import AccountManagePage from "./pages/AccountManagePage";
import CategoryInventoryPage from "./pages/CatogeryInventoryPage";
import CustomerPage from "./pages/CustomerPage";
import HomePage from "./pages/HomePage";
import InternetPackVariantPage from "./pages/InternetPackVariantPage";
import InternetPackagePage from "./pages/InternetPackagePage";
import LoginPage from "./pages/LoginPage";
import ProjectPage from "./pages/ProjectPage";
import ReportBastPage from "./pages/ReportBastPage";
import SettingPage from "./pages/SettingPage";
import SchedulerPage from "./pages/ShedulerPage";
import { default as UnderConstructionPage } from "./pages/UnderConstrcutionPage";
import UserRegistrationPage from "./pages/UserRegistrationPage";
import WarehouseInventoryPage from "./pages/WarehouseInventoryPage";
import WarehousesPage from "./pages/WarehousesPage";
import CustomerExpiredPage from "./pages/customer/CustomerExpiredPage";
import ImportCustomerPage from "./pages/customer/ImportCustomerPage";
import RequestChangePackagePage from "./pages/customer/RequestChangePackagePage";
import { AutoTransferListPage } from "./pages/finance/AutoTransferListPage";
import BillingPage from "./pages/finance/BillingPage";
import DetailPointSales from "./pages/finance/DetailPointSales";
import PointSalesPage from "./pages/finance/PointSalesPage";
import ManageOltPage from "./pages/infrastructure/ManageOltPage";
import ManageOltProfilePage from "./pages/infrastructure/ManageOltProfilePage";
import ProjectUptimePage from "./pages/infrastructure/ProjectUptimePage";
import ReportPerformancePage from "./pages/infrastructure/ReportPerformancePage";
import AcceptOutgoingPage from "./pages/inventory/AcceptOutgoingPage";
import DataModemPage from "./pages/inventory/DataModemPage";
import HistoryModemPage from "./pages/inventory/HistoryModemPage";
import InventoryMasterPage from "./pages/inventory/InventoryMasterPage";
import ModemInventoryPage from "./pages/inventory/ModemInventoryPage";
import ReturnInventoryPage from "./pages/inventory/ReturnInventoryPage";
import SetupModemPage from "./pages/inventory/SetupModemPage";
import WarehouseInventoryOutHistoryPage from "./pages/inventory/WarehouseInventoryOutHistoryPage";
import WarehouseInventoryOutPage from "./pages/inventory/WarehouseInventoryOutPage";
import WarehouseInventoryUploadPage from "./pages/inventory/WarehouseInventoryUploadPage";
import WarehouseManualAddInventoryPage from "./pages/inventory/WarehouseManualAddInventoryPage";
import WarehouseManualTransferPage from "./pages/inventory/WarehouseManualTransferPage";
import WarehouseTransferUploadPage from "./pages/inventory/WarehouseTransferUploadPage";
import ManagePartnersPage from "./pages/project/ManagePartnersPage";
import ManageVendorPage from "./pages/project/ManageVendorPage";
import ProjectVendorListPage from "./pages/project/ProjectVendorListPage";
import ProjectVendorReportPage from "./pages/project/ProjectVendorReportPage";
import PromoPackagePage from "./pages/promo/PromoPackagePage";
import ReportBastDismantlePage from "./pages/report/ReportBastDismantlePage";
import ReportBastRelocatePages from "./pages/report/ReportBastRelocatePage";
import BillingSalesPage from "./pages/sales/BillingSalesPage";
import RegistrationBySalesPage from "./pages/sales/RegistrationBySalesPage";
import SalesRegisteredPage from "./pages/sales/SalesRegisteredPage";
import DismantleSchedule from "./pages/schedules/DismantleSchedule";
import RelocateSchedule from "./pages/schedules/RelocateSchedule";
import AppAlertPage from "./pages/settings/AppAlertPage";
import AuditLogsPage from "./pages/settings/AuditLogsPage";
import BannerSettingPage from "./pages/settings/BannerSettingPage";
import CoverageSettingPage from "./pages/settings/CoverageSettingPage";
import RbacSettingPage from "./pages/settings/RbacSettingPage";
import HistoryTechnician from "./pages/technician/TechnicianHistoryPage";
import TechnicianPage from "./pages/technician/TechnicianPage";
import TicketingPage from "./pages/ticketing/TicketingPage";

export const router = [
  {
    "path": "/login",
    "name": "Login",
    "component": LoginPage
  },
  {
    "path": "/home",
    "name": "Home",
    "component": HomePage
  },
  {
    "path": "/users",
    "name": "User",
    "component": UserRegistrationPage
  },
  {
    "path": "/roles",
    "name": "Akun Role",
    "component": AccountManagePage
  },
  {
    "path": "/roles",
    "name": "Akun Role",
    "component": AccountManagePage
  },
  {
    "path": "/pelanggan",
    "name": "Manajemen Pelanggan",
    "component": CustomerPage
  },
  {
    "path": "/pelanggan/request",
    "name": "Request Ganti Paket",
    "component": RequestChangePackagePage
  },
  {
    "path": "/pelanggan/jatuh-tempo",
    "name": "Pelanggan Belum Bayar",
    "component": CustomerExpiredPage
  },
  {
    "path": "/pelanggan/import",
    "name": "Pelanggan Belum Bayar",
    "component": ImportCustomerPage
  },
  {
    "path": "/tickets",
    "name": "Ticketing",
    "component": TicketingPage
  },
  {
    "path": "/teknisi/riwayat",
    "name": "Riwayat Pemasangan",
    "component": HistoryTechnician
  },
  {
    "path": "/teknisi",
    "name": "Manajemen Teknisi",
    "component": TechnicianPage
  },
  {
    "path": "/jadwal/instalasi",
    "name": "Jadwal Instalasi",
    "component": SchedulerPage
  },
  {
    "path": "/jadwal/dismantle",
    "name": "Jadwal Dismantle",
    "component": DismantleSchedule
  },
  {
    "path": "/jadwal/relocate",
    "name": "Jadwal Relokasi",
    "component": RelocateSchedule
  },
  {
    "path": "/paket/tipe",
    "name": "Manajemen Paket",
    "component": InternetPackVariantPage
  },
  {
    "path": "/paket",
    "name": "Manajemen Paket",
    "component": InternetPackagePage
  },
  {
    "path": "/project",
    "name": "Manajemen Project",
    "component": ProjectPage
  },
  {
    "path": "/project/partners",
    "name": "Manajemen Mitra",
    "component": ManagePartnersPage
  },
  {
    "path": "/project/:id/work",
    "name": "Pekerjaan Proyek",
    "component": ProjectVendorListPage
  },
  {
    "path": "/project-vendor/:id/report",
    "name": "Progress Report Vendor",
    "component": ProjectVendorReportPage
  },
  {
    "path": "/project/vendor",
    "name": "Project Vendor",
    "component": ManageVendorPage
  },
  {
    "path": "/setup-modem/form",
    "name": "Form Setup Modem",
    "component": SetupModemPage
  },
  {
    "path": "/setup-modem/riwayat-modem",
    "name": "Form Setup Modem",
    "component": HistoryModemPage
  },
  {
    "path": "/setup-modem/data-modem",
    "name": "Form Setup Modem",
    "component": DataModemPage
  },
  {
    "path": "/setup-modem/olt",
    "name": "Manajemen OLT",
    "component": ManageOltPage
  },
  {
    "path": "/setup-modem/olt-profile",
    "name": "Manajemen Profil OLT",
    "component": ManageOltProfilePage
  },
  {
    "path": "/infrastructure/report/:id/project",
    "name": "Report Performance",
    "component": ProjectUptimePage
  },
  {
    "path": "/infrastructure/report",
    "name": "Report Performance",
    "component": ReportPerformancePage
  },
  {
    "path": "/form-bast/instalasi",
    "name": "Form BAST",
    "component": ReportBastPage
  },
  {
    "path": "/form-bast/dismantle",
    "name": "Form BAST",
    "component": ReportBastDismantlePage
  },
  {
    "path": "/form-bast/relocate",
    "name": "Form BAST Relocate",
    "component": ReportBastRelocatePages
  },
  {
    "path": "/settings/tnc",
    "name": "Halaman Settting",
    "component": UnderConstructionPage
  },
  {
    "path": "/settings/app-alert",
    "name": "Halaman Penumuman Aplikasi",
    "component": AppAlertPage
  },
  {
    "path": "/settings/banner",
    "name": "Halaman Banner Aplikasi",
    "component": BannerSettingPage
  },
  {
    "path": "/settings/coverage",
    "name": "Halaman Setting Coverage",
    "component": CoverageSettingPage
  },
  {
    "path": "/settings/audit-log",
    "name": "Halaman Setting Coverage",
    "component": AuditLogsPage
  },
  {
    "path": "/settings/rbac",
    "name": "Halaman Setting RBAC",
    "component": RbacSettingPage
  },
  {
    "path": "/settings",
    "name": "Halaman Settting",
    "component": SettingPage
  },
  {
    "path": "/warehouses/:id/inventory",
    "name": "Manajemen Inventory dalam Gudang",
    "component": WarehouseInventoryPage
  },
  {
    "path": "/warehouses/:id/manual-inventory",
    "name": "Tambah Tranfer dari Gudang",
    "component": WarehouseManualAddInventoryPage
  },
  {
    "path": "/warehouses/:id/manual-transfer",
    "name": "Manual Tranfer dari Gudang",
    "component": WarehouseManualTransferPage
  },
  {
    "path": "/warehouses/:id/transfer-upload",
    "name": "Upload Tranfer dari Gudang",
    "component": WarehouseTransferUploadPage
  },
  {
    "path": "/warehouses/:id/inventory-upload",
    "name": "Upload Inventory Gudang",
    "component": WarehouseInventoryUploadPage
  },
  {
    "path": "/warehouses/:id/inventory-out",
    "name": "Pengeluaran Inventory Gudang",
    "component": WarehouseInventoryOutPage
  },
  {
    "path": "/warehouses/:id/inventory-out-history",
    "name": "Riwayat Pengeluaran Inventory Gudang",
    "component": WarehouseInventoryOutHistoryPage
  },
  {
    "path": "/warehouses/:warehouseId/inventory/:id/modems",
    "name": "Manajemen Inventory Modem",
    "component": ModemInventoryPage,
  },
  {
    "path": "/warehouses/categories",
    "name": "Manajemen Kategori",
    "component": CategoryInventoryPage
  },
  {
    "path": "/warehouses/return",
    "name": "Manajemen Barang Masuk",
    "component": ReturnInventoryPage
  },
  {
    "path": "/warehouses/inventory/:id/accept",
    "name": "Terima Barang Keluar",
    "component": AcceptOutgoingPage
  },
  {
    "path": "/warehouses/inventory",
    "name": "Terima Barang Keluar",
    "component": InventoryMasterPage
  },
  {
    "path": "/warehouses",
    "name": "Manajemen Gudang",
    "component": WarehousesPage
  },
  {
    "path": "/promo/package",
    "name": "Manajemen Promo",
    "component": PromoPackagePage
  },
  {
    "path": "/finance/billing",
    "name": "Billing",
    "component": BillingPage
  },
  {
    "path": "/finance/auto-transfer-list",
    "name": "Auto Transfer List",
    "component": AutoTransferListPage
  },
  {
    "path": "/point-sales/:id/detail",
    "name": "Detail Point Sales",
    "component": DetailPointSales
  },
  {
    "path": "/point-sales",
    "name": "Poin Sales & Affiliator",
    "component": PointSalesPage
  },
  {
    "path": "/sales/register",
    "name": "Halaman Registrasi",
    "component": RegistrationBySalesPage
  },
  {
    "path": "/sales/data",
    "name": "Halaman Registrasi",
    "component": SalesRegisteredPage
  },
  {
    "path": "/sales/billing",
    "name": "Tagihan",
    "component": BillingSalesPage
  },
];