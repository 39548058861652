import { useState } from "react";
import { Modal } from "react-bootstrap";
import { useControlPermission } from "../../utils/ActionPermissionUtils";
import { usePresetTemplateInventory } from "../../utils/inventory/PresetInventory";
import MainLayout from "../../components/layouts/MainLayout";
import AlertBox from "../../components/AlertBox";
import Box from "../../components/Box";
import BoxPreviewExcelCustomer from "../../components/customer/BoxPreviewExcelCustomer";
import {
  createImportCustomerUploader,
  downloadPresetsImportCustomer,
  importBulkCustomer,
} from "../../api/customer";
import { useGroupListHooks } from "../../utils/ProjectsUtilHooks";

export default function ImportCustomerPage() {
  let { hasAccess } = useControlPermission();

  let { filesPreset } = usePresetTemplateInventory();

  const [alert, setAlert] = useState({ show: false, message: "", type: "" });
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState(null);
  const [file, setFile] = useState(null);

  const [previewCustomers, setPreviewCustomers] = useState([]);
  const [customerMeta, setCustomerMeta] = useState({});
  const [reports, setReports] = useState({});

  const onSuccess = (message) => {
    setAlert({ show: true, message, type: "success" });
  };

  const onCancelFile = () => {
    setFile(null);
    setPreviewCustomers([]);
    setReports([]);
  };

  const onChangeFile = (event) => {
    setFile(event.target.files[0]);
    onChangeUpload(event);
  };

  const onChangeUpload = async (event) => {
    let formData = new FormData();
    formData.append("file", event.target.files[0]);
    uploadFileExcel(formData);
  };

  const uploadFileExcel = async (formData) => {
    setLoading(true);

    try {
      let { data } = await createImportCustomerUploader(formData);
      setPreviewCustomers(data.data);
      setCustomerMeta(data.meta);
    } catch (error) {
      let message = "";
      if (error.response) {
        let { data } = error.response;
        message = data.message;
      } else {
        message = "Something Error Happened";
      }

      setAlert({ show: true, message, type: "danger" });
    }

    setLoading(false);
  };

  const resetData = () => {
    setFile(null);
    setPreviewCustomers([]);
  };

  const bulkImportCustomer = async () => {
    setModal("loading");

    const formData = {
      customers: previewCustomers,
    };
    try {
      let { data } = await importBulkCustomer(formData);
      setReports(data.data);
      onSuccess(data.message);
      resetData();
    } catch (error) {
      let message = "";
      if (error.response) {
        let { data } = error.response;
        message = data.message;
      } else {
        message = "Something Error Happened";
      }

      setAlert({ show: true, message, type: "danger" });
    }

    setModal("report");
  };

  return (
    <MainLayout resource_key="manage-customer">
      <div className="container-fluid">
        <h1 className="h3 mb-4 text-gray-800">Import Pelanggan</h1>
        <AlertBox {...alert} setAlert={setAlert} />
        <div className="row">
          {hasAccess("upload-customer") ? (
            <div className="col-12 col-lg-12">
              <Box title="Form Unggah File Pelanggan" loading={loading}>
                <div className="row flex-lg-row-reverse">
                  <div className="col-12 col-lg-9 mb-2">
                    <div className="alert alert-warning h-100">
                      <b>Perhatian</b> Mohon unggah berkas file yang sesuai dengan template yang
                      sudah disediakan
                      <div className="d-flex flex-row flex-wrap" style={{ gap: "10px" }}>
                        <a
                          href={filesPreset["import-customer-address-template"]}
                          download
                          className="btn btn-success my-2"
                        >
                          <i className="fa fa-file-excel"></i> Daftar ID Alamat
                        </a>

                        <button
                          className="btn btn-success my-2"
                          onClick={() => setModal("preset-project")}
                        >
                          <i className="fa fa-file-excel"></i> Template Impor Pelanggan
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-lg-3">
                    <div className="card p-3 pb-0">
                      <div className="">
                        <FormExcelUploader
                          file={file}
                          onChangeFile={onChangeFile}
                          onCancel={onCancelFile}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </Box>
            </div>
          ) : null}

          <div className="col-12 col-lg-12">
            <BoxPreviewExcelCustomer preview_customers={previewCustomers} meta={customerMeta} />
            <div className="form-group d-flex justify-content-end">
              {hasAccess("upload-customer") && (
                <button
                  className="btn btn-lg btn-primary"
                  onClick={() => setModal("confirm")}
                  disabled={previewCustomers.length === 0}
                >
                  <i className="fa fa-upload"></i> Impor Data Pelanggan
                </button>
              )}
            </div>
          </div>
        </div>
      </div>

      <ModalConfirm
        show={modal === "confirm"}
        onClose={() => setModal(null)}
        onBulkAdd={() => bulkImportCustomer()}
      />
      <ModalReportUpload
        show={modal === "report"}
        reports={reports}
        onClose={() => setModal(null)}
      />
      <ModalDownloadPresets show={modal === "preset-project"} onClose={() => setModal(null)} />

      <LoadingModalUpload show={modal === "loading"} />
    </MainLayout>
  );
}

function FormExcelUploader({ file, onChangeFile, onCancel }) {
  return (
    <>
      <div className="form-group">
        <label>File Excel Import Pelanggan</label>
        {file ? (
          <div className="alert alert-success">
            <i className="fa fa-file-excel"></i> {file.name}
            <button type="button" className="close" aria-label="Close" onClick={() => onCancel()}>
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        ) : (
          <div className="custom-file">
            <label className="custom-file-label">File Excel ...</label>
            <input
              type="file"
              className="custom-file-input"
              accept=".xlsx"
              onChange={onChangeFile}
            />
          </div>
        )}
      </div>
    </>
  );
}

function ModalDownloadPresets({ show, onClose }) {
  const [alert, setAlert] = useState({ show: false, message: "", type: "" });
  const [loading, setLoading] = useState(false);

  const { projects } = useGroupListHooks();

  const [formFilter, setFormFilter] = useState({
    project_id: "",
  });

  const downloadFile = async () => {
    setLoading(true);

    try {
      let response = await downloadPresetsImportCustomer({ ...formFilter });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "Template Import Pelanggan.xlsx");
      document.body.appendChild(link);
      link.click();
      handleClose();
    } catch (error) {
      let message = "Something wrong ... ";

      if (error.response) {
        let { data } = error.response;
        let responseObj = await data.text();
        let responseData = JSON.parse(responseObj);
        message = responseData?.message;
      } else {
        message = "Something Error Happened";
      }

      setAlert({ show: true, message: message, type: "danger" });
    }
    setLoading(false);
  };
  const submitFilter = (event) => {
    event.preventDefault();
    downloadFile();
  };
  const handleClose = () => {
    setAlert({ show: false, message: "", type: "" });
    onClose();
  };

  return (
    <Modal show={show} onHide={() => handleClose()}>
      <Modal.Header closeButton>
        <Modal.Title>Preset Project</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <AlertBox {...alert} setAlert={setAlert} />

        <form onSubmit={(event) => submitFilter(event)}>
          <div className="form-group">
            <label htmlFor="project_group">Kelompok/Project</label>
            <select
              className="input form-control"
              value={formFilter.project_id}
              required
              onChange={(event) => setFormFilter({ ...formFilter, project_id: event.target.value })}
            >
              <option value="">-- Kelompok/Project --</option>
              {projects.map((project, index) => (
                <option key={index} value={project.id}>
                  {project.name}
                </option>
              ))}
            </select>
          </div>
          <div className="form-group">
            <button className="btn btn-primary btn-block" disabled={loading}>
              <i className="fas fa-download"></i> {!loading ? "Unduh Data" : "Harap Tunggu ..."}
            </button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
}

function ModalConfirm({ show, onClose, onBulkAdd }) {
  let [alert, setAlert] = useState({ show: false, message: "", type: "" });

  const handleClose = () => {
    setAlert({ show: false, message: "", type: "" });
    onClose();
  };

  const upload = async () => {
    onBulkAdd();
  };

  return (
    <Modal show={show} onHide={() => handleClose()}>
      <form onSubmit={(event) => event.preventDefault()}>
        <Modal.Header closeButton>
          <Modal.Title>Konfirmasi Pengunggahan File Impor Pelanggan via Excel</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AlertBox {...alert} setAlert={setAlert} />
          Apakah anda yakin untuk melakukan upload via file excel? Anda tidak dapat melakukan
          tindakan kembali jika terjadi kesalahan. Pastikan data anda sudah benar.
          <div className="d-flex justify-content-center py-4">
            <button className="btn btn-lg btn-success mr-2" onClick={() => upload()}>
              Ya, Saya yakin
            </button>
            <button className="btn btn-lg btn-secondary" onClick={() => handleClose()}>
              Tidak
            </button>
          </div>
        </Modal.Body>
      </form>
    </Modal>
  );
}

// TODO: change modal loading
function LoadingModalUpload({ show }) {
  return (
    <Modal show={show} onHide={() => {}}>
      <div className="alert alert-warning mb-0">
        <h4>
          <i className="fa fa-hourglass"></i> Harap menunggu.
        </h4>
        <span>Impor pelanggan sedang dilakukan sistem ...</span>
      </div>
    </Modal>
  );
}

function ModalReportUpload({ show, reports, onClose }) {
  const handleClose = () => {
    onClose();
  };

  return (
    <Modal show={show} onHide={() => handleClose()} size="lg">
      <form onSubmit={(event) => event.preventDefault()}>
        <Modal.Header closeButton>
          <Modal.Title>Report Impor Pelanggan</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="alert alert-secondary">
            <h4>Laporan Import</h4>
            <p>Jumlah Impor Sukses: {reports.success?.length || 0}</p>
            <p>Jumlah Impor Gagal: {reports.failed?.length || 0}</p>
          </div>
          {reports.failed?.length > 0 ? (
            <>
              <div className="alert alert-danger">Berikut pelanggan yang gagal diimpor</div>
              <table className="table table-sm table-bordered table-striped">
                <thead>
                  <tr>
                    <th>Nama Pelanggan</th>
                    <th>Alasan</th>
                  </tr>
                </thead>
                <tbody>
                  {reports.failed.map((failed, index) => (
                    <tr key={index}>
                      <td>{failed.customer.name}</td>
                      <td>{failed.error}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </>
          ) : null}
        </Modal.Body>
      </form>
    </Modal>
  );
}
