import { useState } from "react";
import Box from "../Box";

export default function BoxPreviewExcelCustomer({ preview_customers, meta }) {
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10; // Number of items to display per page

  // if (preview_customers.length === 0) return <div />;
  // Calculate the start and end indices for the current page
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  // Slice the data array to get the current page's data
  const currentData = preview_customers.slice(startIndex, endIndex);

  return (
    <Box title={`Preview Pelanggan yang akan diimpor (Total data: ${meta.count || 0})`}>
      <div className="table-responsive">
        <table className="table table-sm table-striped table-bordered table-hover">
          <thead>
            <tr>
              <th>No</th>
              <th>Nama</th>
              <th>Email</th>
              <th>No. Telp</th>
              <th>Jenis Kelamin</th>
              <th>Nomor Identitas</th>
              <th>Tipe identitas</th>
              <th>Tgl. Lahir</th>
              <th>Tempat Lahir</th>
              <th>Status Bangungan</th>
              <th>Paket Internet</th>
              <th>Alamat</th>
              <th>RT</th>
              <th>RW</th>
              <th>Provinsi</th>
              <th>Kota/Kab</th>
              <th>Kecamatan</th>
              <th>Kelurahan</th>
              <th>Kode Pos</th>
              <th>Alamat Pemasangan</th>
              <th>RT Pemasangan</th>
              <th>RW Pemasangan</th>
              <th>Provinsi Pemasangan</th>
              <th>Kota/Kab Pemasangan</th>
              <th>Kecamatan Pemasangan</th>
              <th>Kelurahan Pemasangan</th>
              <th>Kode Pos Pemasangan</th>
              <th>Modem Brand</th>
              <th>Modem SN</th>
              <th>GPON SN</th>
              <th>ODP</th>
              <th>ODC</th>
              <th>Framed Pool</th>
              <th>Paket Mulai</th>
              <th>Paket Berakhir</th>
            </tr>
          </thead>
          <tbody>
            {preview_customers.length === 0 && (
              <tr>
                <td colSpan={35}>Belum ada data</td>
              </tr>
            )}
            {currentData.map((customer, index) => (
              <tr key={index}>
                <td>{startIndex + index + 1}</td>
                <td>{customer.name}</td>
                <td>{customer.email}</td>
                <td>{customer.phone_number}</td>
                <td>{customer.gender}</td>
                <td>{customer.identity_number}</td>
                <td>{customer.identity_number_type}</td>
                <td>{customer.birthdate}</td>
                <td>{customer.birthdate_place}</td>
                <td>{customer.building_ownership_status}</td>
                <td>{customer.internet_package?.name}</td>
                <td>{customer.addresses?.address}</td>
                <td>{customer.addresses?.rt}</td>
                <td>{customer.addresses?.rw}</td>
                <td>{customer.addresses?.province}</td>
                <td>{customer.addresses?.kota_kab}</td>
                <td>{customer.addresses?.kecamatan}</td>
                <td>{customer.addresses?.kelurahan}</td>
                <td>{customer.addresses?.postal_code}</td>
                <td>{customer.installation_addresses?.address}</td>
                <td>{customer.installation_addresses?.rt}</td>
                <td>{customer.installation_addresses?.rw}</td>
                <td>{customer.installation_addresses?.province}</td>
                <td>{customer.installation_addresses?.kota_kab}</td>
                <td>{customer.installation_addresses?.kecamatan}</td>
                <td>{customer.installation_addresses?.kelurahan}</td>
                <td>{customer.installation_addresses?.postal_code}</td>
                <td>{customer.inventory_modem?.brand}</td>
                <td>{customer.inventory_modem?.serial_number_modem}</td>
                <td>{customer.inventory_modem?.serial_number_gpon}</td>
                <td>{customer.odp}</td>
                <td>{customer.odc}</td>
                <td>{customer.framed_pool || "-"}</td>
                <td>{customer.plan_start}</td>
                <td>{customer.plan_end}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="d-flex justify-content-end" style={{ gap: "10px" }}>
        <button
          className="btn btn-primary"
          onClick={() => setCurrentPage((prevPage) => prevPage - 1)}
          disabled={currentPage === 1}
        >
          <i className="fa fa-arrow-left"></i> Prev
        </button>
        <button
          className="btn btn-primary"
          onClick={() => setCurrentPage((prevPage) => prevPage + 1)}
          disabled={endIndex >= preview_customers.length}
        >
          Next <i className="fa fa-arrow-right"></i>
        </button>
      </div>
    </Box>
  );
}
